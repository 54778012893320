import React from 'react'
import PropTypes from 'prop-types'
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import { Helmet } from 'react-helmet'
import { graphql, Link as GatsbyLink } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import styled from "styled-components";
import theme from "../theme"
import { Element } from "react-scroll";
import Faqs from "../components/Faqs";
import Contact from "../components/Contact";


const StyledBox = styled(Box)`
  ${({ $img }) => `background-image: url("${$img.fluid.src}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;


export const ClankyPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
  img,
  date
}) => {
  const PostContent = contentComponent || Content
  return (
    <Box position="relative" zIndex={1}>
      {helmet || ''}
      <StyledBox width="100%" height={{ xs: theme.spacing(60.75), lg: theme.spacing(123.75) }} position="absolute" top={0} zIndex={-1} $img={img} />

      <Container maxWidth="xl" >
        <Box pt={{ xs: 12, md: 32 }} mb={{ xs: 5 }}>
          <Card>
            <Box px={{ xs: 4, md: 17 }} py={{ xs: 4, lg: 9.25 }}>
              <Box fontSize={{ xs: theme.spacing(1.5) }} color="#2E2E2E" pb={{ xs: 2, lg: 4 }} fontFamily="Sofia Pro bold">
                <GatsbyLink to="/" style={{ textDecoration: "none", color: "#777777" }}>
                  Späť na hlavnú stránku
                </GatsbyLink>
              </Box>
              <Box fontSize={{ xs: theme.spacing(2.75), lg: theme.spacing(4) }} fontFamily="Sofia Pro bold" color="primary.main" pb={{ xs: 1 }}>
                {title}
              </Box>
              <Box fontSize={{ xs: theme.spacing(2), lg: theme.spacing(2.5) }} color="#2E2E2E" pb={{ xs: 2 }}>
                {date}
              </Box>

              <Box fontSize={{ xs: theme.spacing(2), lg: theme.spacing(2.5) }} color="#2E2E2E" pb={{ xs: 2 }}>
                {description}
              </Box>
              <Box>
                <PostContent content={content} />
              </Box>
            </Box>
          </Card>
        </Box>
        <Box mb={{ xs: 5 }}>
          <Element name="faqs">
            <Faqs />
          </Element>
        </Box>
        <Box mb={{ xs: 5 }}>
          <Element name="contact">
            <Contact />
          </Element>
        </Box>
      </Container>


    </Box>

  )
}

ClankyPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const ClankyPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ClankyPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        date={post.frontmatter.date}
        helmet={
          <Helmet titleTemplate="%s | Kamerové systémy, s.r.o.">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        img={post.frontmatter.featuredimage.childImageSharp}
      />
    </Layout>
  )
}

ClankyPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ClankyPost

export const pageQuery = graphql`
  query ClankyPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title        
        description
        date
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
